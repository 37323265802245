<template>
  <div class="lineCharts"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {};
  },
  props: {
    ChartsData: {
      type: Object,
      require: true,
      default: null
    }
  },
  mounted() {
    if (this.ChartsData !== null) {
      this.init();
    }
  },
  watch: {
    ChartsData() {
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      let chartDom = document.querySelector('.lineCharts');
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let str = `${params[0].name}<br/>`;
            params.forEach(item => {
              str = str.concat(`${item.marker + item.seriesName}:${((item.value * 1000) / 1000).toFixed(3)}%<br/>`);
            });
            return str;
          }
        },
        grid: {
          top: '8%',
          right: '3%',
          left: '3%',
          bottom: '20%'
        },
        legend: {
          data: ['设备效率', '运行时长', '待机时长', '故障时长', '离线时长'],
          bottom: '1%',
          left: '5%',
          itemWidth: 13,
          itemHeight: 13,
          itemGap: 30
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.ChartsData.xAxisValues,
          axisLabel: {
            interval: 0
          }
        },
        yAxis: {
          type: 'value',
          name: '百分比%'
        },
        series: [
          {
            name: '设备效率',
            type: 'line',
            data: this.ChartsData.oee,
            itemStyle: {
              color: '#5a5a5a',
              borderColor: '#fff'
            }
          },
          {
            name: '运行时长',
            type: 'line',
            data: this.ChartsData.runningRate,
            itemStyle: {
              color: '#19a572',
              borderColor: '#fff'
            }
          },
          {
            name: '待机时长',
            type: 'line',
            data: this.ChartsData.freeRate,
            itemStyle: {
              color: '#f39800',
              borderColor: '#fff'
            }
          },
          {
            name: '故障时长',
            type: 'line',
            data: this.ChartsData.alarmRate,
            itemStyle: {
              color: '#ea3837',
              borderColor: '#fff'
            }
          },
          {
            name: '离线时长',
            type: 'line',
            data: this.ChartsData.offlineRate,
            itemStyle: {
              color: '#dbdbdb',
              borderColor: '#fff'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.lineCharts {
  height: 600px;
}
</style>
